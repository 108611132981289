import { lazy } from 'react';
import { withRouter } from 'react-router';

const Dashboard = withRouter(lazy(() => import('features/dashboard/component/dashboard')));

// const MachineList = withRouter(lazy(() => import('features/machineList/container/machineList')));
// const MachineDetail = withRouter(lazy(() => import('features/machineList/container/machineDetail')));

// const CompanyList = withRouter(lazy(() => import('features/company/container/company')));
// const CompanyDetail = withRouter(lazy(() => import('features/company/container/companyDetail')));

const TerracloudBackupList = withRouter(lazy(() => import('features/terracloud/container/terracloudBackup')));
const ApiKeyList = withRouter(lazy(() => import('features/apiKey/container/apiKey')));

export {
	Dashboard,
	// MachineList, MachineDetail,
	// CompanyList, CompanyDetail,
	TerracloudBackupList,
	ApiKeyList,
}
