import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-table/react-table.css';
import 'assets/styles/datatables.min.css'

import './assets/styles/index.scss';
import Layout from 'shared/hoc/layout/component/layout.component'
import AuthService from 'shared/services/auth.service';
import Spinner from 'shared/components/spinner/spinner';
import RouteManager from 'shared/hoc/permission/routeManager';

// import containers, for which lazy loading is not needed, as they will be rendered for all users
import NotFound from 'shared/components/404/404';
import Profile from 'features/profile/container/profile';
import Login from 'features/login/container/login.container';
import RegisterUser from 'features/register/container/register';
import ForgetPassword from 'features/password/container/forgetPassword';
import ResetPassword from 'features/password/container/resetPassword';
import EmailVerification from 'features/emailVerification/component/validate';

/*
* import async components in order to lazy load the containers, which will be rendered conditionally
* based on the permissions user is having
*/
import * as asyncComponents from 'shared/hoc/asyncComponents';

const App: React.FC = () => {
	const isLoggedIn = AuthService.checkLogin();

	if (isLoggedIn) {
		return (
			<Layout>
				<Suspense fallback={<Spinner />}>
					<Switch>
						<Route
							exact path='/'
							component={asyncComponents.Dashboard} />

						{/* <RouteManager
							permission={['customerLicenseMachineList']}
							exact path='/machine/list' component={asyncComponents.MachineList} />
						<RouteManager
							permission={['customerLicenseMachineRead']}
							exact path='/machine/:id' component={asyncComponents.MachineDetail} />

						<RouteManager
							permission={['phoenixCompanyList']}
							exact path='/company/list' component={asyncComponents.CompanyList} />
						<RouteManager
							permission={['phoenixCompanyRead']}
							exact path='/company/:id' component={asyncComponents.CompanyDetail} /> */}

						<RouteManager
							permission={['tseBackupHistoryList']}
							exact path='/tse-backup/history' component={asyncComponents.TerracloudBackupList} />
						{/* <RouteManager
							permission={['apiKeyList']}
							exact path='/api-key/list' component={asyncComponents.ApiKeyList} /> */}
						<Route exact path='/api-key/list' component={asyncComponents.ApiKeyList} />
						<Route exact path='/profile' component={Profile} />
						<Route exact path='/404' component={NotFound} />
						<Redirect to='/404' />
					</Switch>
				</Suspense>
			</Layout>
		);
	} else {
		// lazy loading not needed for login, forget password, reset password APIs
		return (
			<Switch>
				<Route path='/login' component={Login} />
				<Route path='/register/user' component={RegisterUser} />
				<Route path='/forget-password' component={ForgetPassword} />
				<Route path='/reset-password/:token' component={ResetPassword} />
				<Route exact path='/email/verification' component={EmailVerification} />
				<Redirect to='/login' />
			</Switch>
		);
	}
}

export default App;
