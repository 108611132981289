import { combineReducers } from 'redux';
import login from 'features/login/store/login.reducer';
import loadingReducer from './loadingReducer';
import machineListReducer from 'features/machineList/store/machineList.reducer';
import companyListReducer from 'features/company/store/company.reducer';
import terraCloudBackupReducer from 'features/terracloud/store/terracloudBackup.reducer';
import apiKeyListReducer from 'features/apiKey/store/apiKey.reducer';
import { State, Action } from 'shared/interface';

import * as actionTypes from 'store/action-types';

const appReducer = combineReducers({
	auth: login,
	loading: loadingReducer,
	machineList: machineListReducer,
	companyList: companyListReducer,
	terraCloudBackup: terraCloudBackupReducer,
	apiKeyList: apiKeyListReducer,
});

const rootReducer = (state: State | undefined, action: Action): State => {
	if (action.type === actionTypes.AUTH_LOGOUT_SUCCESS || action.type === actionTypes.AUTH_LOGOUT_FAIL) {
		localStorage.removeItem('authData');
		localStorage.removeItem('userData');
		state = undefined;
	}
	return appReducer(state, action);
};

export default rootReducer;
