const de = {
	"dashboardTitle": "Dashboard",
	"autoGeneratedLicenseGraphTitle": "Automatisch generierte Lizenz",
	"upcomingCreatedLicenseGraphTitle": "Insgesamt erstellte Lizenz",
	"daily": "Täglich",
	"weekly": "wöchentlich",
	"monthly": "monatlich",
	"today": "Heute",
	"yesterday": "Gestern",
	"thisWeek": "Diese Woche",
	"lastWeek": "Letzte Woche",
	"thisMonth": "Diesen Monat",
	"lastMonth": "Im vergangenen Monat",
	"custom": "Brauch",
	"backBtnText": "zurück",
	"organizationsTitle": "organisationen",
	"superAdmin": "super admin",
	"license": "Lizenz",
	"support": "Support",
	"licenses": "Lizenzen",
	"incomingLicensesTitle": "Lizenzanfragen",
	"machineListTitle": "Meine Maschinen",
	"licenseNumberView": "Lizenznummer anzeigen",
	"users": "Benutzer",
	"user": "Benutzer",
	"list": "liste",
	"create": "erstellen",
	"rolesAndPermissions": "Rollen & Berechtigungen",
	"roles": "rollen",
	"role": "rolle",
	"status": "Status",
	"permissions": "berechtigungen",
	"addUser": "Nutzer hinzufügen",
	"supportList": "Support-Liste",
	"licenseList": "lizenzliste",
	"superAdminList": "super-admin-liste",
	"logout": "Ausloggen",
	"home": "Hauptmenü",
	"view": "Ansicht",
	"createLicense": "Lizenz erstellen",
	"delete": "Löschen",
	"restore": "Wiederherstellen",
	"edit": "Bearbeiten",
	"all": "Alles",
	"customer": "Kunde",
	"Customer": "Kunde",
	"partner": "Partner",
	"Partner": "Partner",
	"customers": "Kunden",
	"leads": "Führt",
	"search": "Suche",
	"licenseNumbers": "Lizenznummern",
	"licenseInformation": "Lizenzinformationen",
	"organizationDetails": "Organisationsdetails",
	"details": "Einzelheiten",
	"profile": "Profil",
	"editUser": "Benutzer bearbeiten",
	"notFoundMsg": "Es tut uns leid, aber die von Ihnen gesuchte Seite wurde nicht gefunden. Überprüfen Sie die URL auf Fehler und klicken Sie dann in Ihrem Browser auf die Schaltfläche Aktualisieren oder versuchen Sie, etwas anderes in unserer App zu finden.",
	"pageNotFound": "Seite nicht gefunden",
	"actions": "Aktionen",
	"orgTimeline": "Organisationszeitleiste",
	"noDataFoundText": "Keine Daten gefunden",
	"serialNumber": "Nr.",
	"name": "Name",
	"email": "Email",
	"recipientEmail": "Empfänger E-Mail",
	"senderEmail": "Absender E-Mail",
	"password": "Passwort",
	"oldPassword": "Altes Passwort",
	"newPassword": "Neues Passwort",
	"confirmPassword": "Passwort bestätigen",
	"passwordConfirmation": "Passwort Bestätigung",
	"debAuto": "Kundennummer",
	"createdAt": "Erstellt am",
	"updatedAt": "Aktualisiert am",
	"functions": "Funktionen",
	"hardwareKey": "Hardware Key",
	"hardwareId": "Hardware ID",
	"timestampInsert": "Eingefügt um",
	"timestampUpdate": "Aktualisiert am",
	"activeLicense": "Aktive Lizenz",
	"nextLicense": "Aktive Lizenz",
	"posbillVersion": "PosBill Version",
	"partnerId": "Partner-ID",
	"branchId": "Filial-ID",
	"isLocked": "Ist gesperrt",
	"emailVerifiedAt": "E-Mail bestätigt um",
	"zip": "Postleitzahl",
	"telefax": "Telefax",
	"mobile": "Handy",
	"website": "Webseite",
	"billingPeriod": "Abrechnungszeitraum",
	"expiringDate": "Ablaufdatum",
	"licenseNumber": "Lizenznummer",
	"ustId": "Ust Id",
	"iban": "Iban",
	"bic": "Bic",
	"bank": "Bank",
	"saleTaxId": "Umsatzsteuer-ID",
	"vatNumber": "Umsatzsteuer-Identifikationsnummer",
	"salesForceId": "Vertriebsmitarbeiter-ID",
	"acceptAgbDate": "Agb-Datum akzeptieren",
	"acceptSwnDate": "Akzeptiere Swn Datum",
	"expiryDateType": "Art des Ablaufdatums",
	"year": "Jahr",
	"month": "Monat",
	"typeValue": "Typ Wert",
	"value": "Wert",
	"expiryDate": "Ablaufdatum",
	"autoLicense": "Autolizenz",
	"version": "Ausführung",
	"autoUpdate": "Automatisches Update",
	"validFrom": "Gültig ab",
	"validTo": "Gültig bis",
	"newLicense": "Neue Lizenz",
	"manageModules": "Module verwalten",
	"lock": "Sperren",
	"locked": "Verschlossen",
	"unlock": "Freischalten",
	"licenseLock": "Lizenzsperre",
	"licenseUnlock": "Lizenz entsperren",
	"machineLock": "Maschinenschloss",
	"machineUnlock": "Maschine entsperren",
	"licenseLocked": "Lizenznummer gesperrt",
	"licenseUnlocked": "Lizenznummer freigeschaltet",
	"machineLocked": "Maschine gesperrt",
	"machineUnlocked": "Maschine entsperrt",
	"machineAutoLicenseEnabled": "Automatische Computerlizenz aktiviert",
	"machineAutoLicenseDisabled": "Automatische Computerlizenz deaktiviert",
	"sendLicenseEmail": "Lizenz-E-Mail senden",
	"generate": "generieren",
	"copyEmailAndPasswordToClipboard": "E-Mail und Passwort in die Zwischenablage kopieren",
	"submit": "Speichern",
	"submitWithEmail": "Senden Sie mit E-Mail",
	"cancel": "Abbrechen",
	"yes": "Ja",
	"no": "Nein",
	"archived": "archiviert",
	"active": "aktiv",
	"Active": "Aktiv",
	"addNewUser": "Neuen Benutzer hinzufügen",
	"id": "Id",
	"module": "Modul",
	"close": "Schließen",
	"noUsersAvailable": "Keine Benutzer verfügbar",
	"slug": "Schnecke",
	"language": "Sprache",
	"german": "Deutsche",
	"english": "Englisch",
	"noPermissionsAvailable": "Keine Berechtigungen verfügbar",
	"changePassword": "Passwort ändern",
	"updateProfileTitle": "Profil Aktualisieren",
	"changePasswordTitle": "Ändern Sie Ihr Passwort",
	"editUserDetails": "Benutzerdetails bearbeiten",
	"newRegisteredUser": "Neuer Benutzer registrieren",
	"inActive": "inaktiv",
	"blocked": "Gesperrt",
	"incomingLicenseList": "Lizenzanfragen",
	"licenseNumbersList": "Liste der Lizenznummern",
	"forgetPassword": "Passwort vergessen",
	"getPasswordResetCode": "Code zum Zurücksetzen des Passworts abrufen",
	"backToLogin": "Zurück zur Anmeldung",
	"noMachinesFound": "Keine Maschinen gefunden",
	"resetYourPassword": "Passwort zurücksetzen",
	"resetPassword": "Passwort zurücksetzen",
	"login": "Anmeldung",
	"register": "Registrieren",
	"privacyPolicy": "Datenschutz-Bestimmungen",
	"hello": "Hallo",
	"licenseDetailsAreHere": "Ihre Lizenzdetails für PosBill finden Sie hier",
	"thankYou": "Danke dir",
	"yourPosbillTeam": "Ihr PosBill-Team",
	"licenseReport": "Lizenzbericht",
	"standard": "Standard",
	"premium": "Premium",
	"demo": "Demo",
	"fun": "Fun",
	"default": "Standard",
	"sql": "SQL",
	"addItem": "Artikel hinzufügen",
	"OK": "OK",
	"addLicense": "Lizenz hinzufügen",
	"addHardware": "Hardware hinzufügen",
	"add": "Hinzufügen",
	"addWithEmail": "Mit E-Mail hinzufügen",
	"send": "senden",
	"openEmail": "E-Mail öffnen",
	"addNewRole": "Neue Rolle hinzufügen",
	"editRole": "Rolle bearbeiten",
	"customerData": "Kundendaten",
	"name1": "Name 1",
	"name2": "Name 2",
	"street": "Straße",
	"zipCode": "Postleitzahl",
	"city": "Stadt",
	"nation": "Nation",
	"phone": "Telefon",
	"fax": "Fax",
	"ust": "Ust",
	"expiryDateValidate": "Bitte wählen Sie das Ablaufdatum",
	"Activate Auto License": "Autolizenz aktivieren",
	"to": "bis",
	"cc": "CC",
	"subject": "Betreff",
	"content": "Inhalt",
	"requiredFieldValidation": "{{field}} ist erforderlich",
	"fieldSelectionValidation": "Bitte {{field}} auswählen",
	"exactLengthValidation": "{{field}} muss genau {{length}} Zeichen haben!",
	"minLengthStrValidation": "{{field}} muss länger als {{length}} Zeichen sein!",
	"minLengthArrValidation": "{{field}} muss mindestens {{length}} Elemente enthalten!",
	"maxLengthStrValidation": "{{field}} muss kürzer als {{length}} Zeichen sein!",
	"maxLengthArrValidation": "{{field}} darf maximal {{length}} artikel enthalten!",
	"minValueValidation": "{{field}} muss größer oder gleich {{value}} sein!",
	"maxValueValidation": "{{field}} muss kleiner oder gleich {{value}}!",
	"typeValidation": "{{field}} muss eine gültige {{type}} sein!",
	"hardwareKeyValidation": "Der Hardwarekey darf nur Ziffern enthalten (darf nicht mit 0 beginnen)",
	"passwordMatchValidation": "Passwörter stimmen nicht überein",
	"domainMatchValidation": "Bitte geben Sie einen gültigen Domainnamen ein!",
	"confirmRestoreMsg": "Möchten Sie den {{field}} wirklich wiederherstellen?",
	"confirmDeleteMsg": "Möchten Sie den {{field}} wirklich entfernen?",
	"confirmLogoutMsg": "Möchten Sie sich wirklich abmelden?",
	"confirmAutoLicense": "Möchten Sie den Status der automatischen Lizenz für eine bestimmte Lizenz wirklich aktualisieren?",
	"confirmChangeLicenseVersion": "Möchten Sie die Lizenzversion wirklich ändern?",
	"confirmChangeLockStatus": "Möchten Sie den Lizenzsperrstatus wirklich ändern?",
	"confirmChangeMachineLockStatus": "Möchten Sie den Status der Maschinensperre wirklich ändern?",
	"confirmMachineAutoLicense": "Möchten Sie den Status der automatischen Computerlizenz wirklich ändern?",
	"noLicensesFound": "Keine Lizenzen gefunden",
	"noExpiredLicensesFound": "Keine abgelaufenen Lizenzen gefunden",
	"addHardwareNotAllowed": "Sie können für diese Organisation keine Hardware hinzufügen, da die Kunden-ID nicht gültig ist",
	"customerIdRequirements": "(Das Feld Kundennummer muss eine Zahl sein und zwischen 1 und 99999 liegen.)",
	"givenCustomerIdIsNotValid": "Kunden-ID - {{customerId}} ist ungülti",
	"relateMachineToCustomer": "Maschine dem Kunden zuordnen",
	"newCustomer": "Neukunde",
	"customerId": "Kundennummer",
	"copied": "Kopiert",
	"copyText": "Text Kopieren",
	"difference": "Unterschied",
	"relate": "sich beziehen",
	"totalRecords": "Gesamtzahl der Datensätze",

	"birdhub": "Birdhub",
	"birdhubTitle": "Birdhub",
	"stores": "Shops",
	"storeList": "Geschäftsliste",
	"inventory": "Inventar",
	"shopDetail": "Shop-Detail",
	"backToShopify": "Zurück zu Shopify",
	"connectWithShopify": "Mit Shopify verbinden",
	"connectToShopify": "Mit Shopify verbinden",
	"addShop": "Shop hinzufügen",
	"addNewShop": "Neuen Shop hinzufügen",
	"shopName": "Laden Name",
	"domain": "Domain",
	"currency": "Währung",
	"plan": "Planen",
	"info": "Die Info",
	"marketplace": "Marktplatz",
	"itemId": "Artikel Identifikationsnummer",
	"itemName": "Artikelname",
	"customerName": "Kundenname",
	"customerEmail": "Kunden-eMail",
	"orderName": "Auftragsname",
	"orderDate": "Bestelldatum",
	"totalPrice": "Gesamtpreis",
	"quantity": "Menge",
	"itemPrice": "Stückpreis",
	"purchasePrice": "Stückpreis",
	"itemDetail": "Artikeldetail",
	"stockDetail": "Bestandsdetail",
	"variantId": "Varianten-ID",
	"locationId": "Standort-ID",
	"stock": "Lager",
	"reserveStock": "Aktien reservieren",
	"reorderStock": "Lager nachbestellen",
	"syncOrders": "Aufträge synchronisieren",
	"shopUninstalledText": "Ihr Shop wurde von shopify deinstalliert",
	"initialSetup": "Ersteinrichtung",
	"birdhubSetup": "Birdhub-Setup",
	"registerWebhooks": "Registrieren Sie Webhooks",
	"webhookInstall": "Webhook installieren",
	"initialSync": "Erstsynchronisation",
	"locations": "Standorte",
	"product": "Produkt",
	"orders": "Aufträge",
	"syncLocation": "Speicherort synchronisieren",
	"syncProduct": "Produkt synchronisieren",
	"initialSetupInfoText": "Sie müssen zuerst den Store einrichten, indem Sie auf Setup klicken. Dadurch wird die App in Ihrem Store konfiguriert.",
	"registerWebhookInfoText": "Wenn Sie die Produkte und Bestellungen auf dem neuesten Stand halten möchten, registrieren Sie die Webhooks für das Live-Update.",
	"initialSyncInfoText": "Hier können Sie Ihre Artikel und Bestellungen manuell synchronisieren. Dies ist nützlich, wenn Sie die App zum ersten Mal installieren.",
	"note": "Hinweis",
	"uninstall": "Deinstallieren",
	"registerWebhookText": "Sind Sie sicher, dass Sie den Webhook registrieren möchten?",
	"uninstallWebhookText": "Möchten Sie Webhook wirklich deinstallieren?",
	"registerWebhookNote": "Ihre automatische Synchronisierung von shoipify wird aktiviert, wenn Sie den Webhook registrieren.",
	"uninstallWebhookNote": "Wenn Sie den Webhook deinstallieren, erhalten Sie keine echten Updates mehr von Shopify.",

	"internal": "intern",
	"global": "global",
	"local": "lokal",
	"birdhubAdmin": "birdhubAdmin",
	"birdhubAdminTitle": "Birdhub Admin",
	"credentials": "Referenzen",
	"credentialList": "Anmeldeinformationsliste",
	"addNewCredential": "Neuen Berechtigungsnachweis hinzufügen",
	"addCredential": "Anmeldeinformationen hinzufügen",
	"updateCredential": "Anmeldeinformationen aktualisieren",
	"credentialInfo": "Anmeldeinformationen",
	"apiId": "API-ID",
	"apiSecretKey": "Geheimer Api-Schlüssel",
	"type": "Art",
	"notes": "Anmerkungen",

	"phoenixAdmin": "phoenixAdmin",
	"phoenixAdminTitle": "Phoenix Admin",
	"companyList": "Firmenliste",
	"companyDetail": "Firmendetail",
	"companyId": "Firmen-ID",
	"phoenixIncomingLicenseList": "Phoenix Eingehende Lizenzliste",
	"phoenixIncomingLicenseTitle": "Phoenix Incoming-Lizenzen",
	"isEmailVerified": "Ist die E-Mail-Adresse bestätigt?",
	"verified": "verifiziert",
	"pending": "steht aus",
	"subscriptions": "Abonnements",
	"subscription": "Abonnement",
	"subscriptionId": "Abonnement-ID",
	"additionalUsers": "Zusätzliche Benutzer",
	"additionalFeatures": "Zusatzfunktionen",
	"bankInfo": "Bank Info",
	"universeId": "Universum-ID",
	"companyName": "Name der Firma",
	"isVerified": "isVerified",
	"manageSubscription": "Abonnement verwalten",
	"resetMasterPassword": "Master-Passwort zurücksetzen",
	"companyLocked": "Firma gesperrt",
	"companyUnlocked": "Unternehmen freigeschaltet",
	"confirmChangeCompanyLockStatus": "Möchten Sie den Sperrstatus der Firma wirklich ändern?",

	"modules": "Module",
	"moduleList": "Modulliste",
	"addNewModule": "Neues Modul hinzufügen",
	"addModule": "Modul hinzufügen",
	"updateModule": "Modul aktualisieren",
	"price": "Preis",

	"package": "Paket",
	"packages": "Pakete",
	"packageList": "Paketliste",
	"updatePackage": "Paket aktualisieren",

	"startPhoenixNow": "Starten Sie jetzt Phoenix",
	"companyAlreadyValidatedMessage": "Firma bereits verifiziert!",
	"companyVerificationSuccessMessage": "Ihre Anmeldung war erfolgreich!",
	"companyVerificationFailMessage": "Es tut uns leid. Ihre Firma konnte nicht verifiziert werden!",

	"emailAlreadyValidatedMessage": "E-Mail bereits bestätigt!",
	"emailVerificationSuccessMessage": "E-Mail erfolgreich bestätigt!",
	"emailVerificationFailMessage": "Es tut uns leid. Ihre E-Mail-Adresse konnte nicht bestätigt werden!",

	"companyListTitle": "Meine Firmen",
	"hospitality": "Gastfreundschaft",
	"retail": "Verkauf",
	"shopListTitle": "Meine Geschäfte",
	"backToHome": "Zurück nach Hause",
	"posbillConnect": "PosBill Connect",
	"isRequestingPermission": "bittet um die Erlaubnis, Folgendes zu tun:",
	"readMyProductInformation": "Lesen Sie meine Produktinformationen",
	"accesssToProductProductVariant": "Zugriff auf Produkt, Produktvariante, Produktabbildung, Sammlung, benutzerdefinierte Sammlung und intelligente Sammlung",
	"readMyOrderInfo": "Lesen Sie meine Bestellinformationen",
	"accesssToAbandonedCheckouts": "Zugriff auf Abandoned Checkout, Fulfillment, Order und Transaction",
	"readMyInventoryInfo": "Lesen Sie meine Inventarinformationen",
	"accessToInventoryLevel": "Zugriff auf Lagerbestand und Lagerartikel",
	"readMyLocationInfo": "Lesen Sie meine Standortinformationen",
	"accessToLocation": "Zugang zum Standort",
	"readMyCustomerInfo": "Lesen Sie meine Kundeninformationen",
	"accessToCustomerAndSavedSearch": "Zugriff auf Kunden- und gespeicherte Suche",
	"deny": "Verweigern",
	"allow": "ermöglichen",
	"invalidToken": "Ungültiges Token!",
	"shopifyInstallationCalcelled": "shopify installierter Prozess abgebrochen",
	"contineWith": "Weiter mit",
	"loginWithDifferentAccount": "Mit anderem Konto anmelden",
	"signup": "Anmelden",
	"authorizeUserInfoText": "Melde dich mit deinem bestehenden Account an oder erstelle dir einen neuen um deinen Shop mit dem PosBill Univers zu verbinden.",
	"additionalInformation": "zusätzliche Information",
	"shopNotFound": "Shop nicht gefunden",

	"terracloud": "Cloud-Backup",
	"terracloudBackupList": "Backupliste",
	"download": "Herunterladen",
	"directoryName": "Verzeichnisname",
	"lastModifiedDate": "Zuletzt geändertes Datum",
	"fileName": "Dateiname",
	"fileSize": "Dateigröße",
	"serial_number": "Ordnungsnummer",
	"checksum": "Prüfsumme",
	"workingDate": "Arbeitsdatum",
	"progressing": "Fortschritt",
	"inProgress": "In Bearbeitung",
	"uploaded": "Hochgeladen",
	"downloaded": "Heruntergeladen",
	"createDownloadRequest": "Download-Anfrage erstellen",
	"activeBackupData": "Aktive Sicherungsdaten",
	"backupInProgressText": "Ihr Backup wird ausgeführt ...",
	"backupReadyText": "Ihr Backup ist fertig",
	"backupFiles": "Sicherungs-Dateien",
	"generateDownloadLink": "Download-Link generieren",
	"generatedLinkText": "Der Backup-Link wird generiert. Klicken Sie auf den Download-Button",
	"downloadBackup": "Backup herunterladen",
	"confirmCreateDownloadRequest": "Wenn Sie eine neue Sicherung anfordern, wird die alte heruntergeladene Sicherung entfernt. Möchten Sie wirklich fortfahren?",

	"apiKeyListTitle": "API-Schlüsselliste",
	"generateNewKey": "Neuen Schlüssel generieren",
	"generateAPIKey": "API-Schlüssel generieren",
	"done": "Erledigt",
	"copyToClipboard": "In die Zwischenablage kopieren",

	"permission-module-Organizations": "Organisationen",
	"permission-module-Widgets": "Widgets",
	"permission-module-Roles": "Rollen",
	"permission-module-Users": "Benutzer",
	"permission-module-EmailTemplates": "E-Mail-Vorlagen",
	"permission-module-EmailTemplateVariables": "E-Mail-Vorlagenvariablen",
	"permission-module-Permissions": "Berechtigungen",
	"permission-module-Licenses": "Lizenzen",
	"permission-module-CustomerLicense": "Kundenlizenz",
	"permission-module-Graphs": "Graph",
	"permission-module-BirdHubCredentials": "Birdhub-Anmeldeinformationen",
	"permission-module-PhoenixModules": "Phoenix-Module",
	"permission-module-PhoenixCompanies": "Phoenix Unternehmen",
	"permission-module-CustomerPhoenixLicense": "Phoenix Kundenlizenzen",
	"permission-module-PhoenixPackages": "Phoenix-Pakete",
	"organizations.superAdminIndex": "Super Admin Liste",
	"organizations.licenseIndex": "Lizenzliste",
	"organizations.supportIndex": "Support-Liste",
	"organizations.show": "Organisation anzeigen",
	"organizations.timeline": "Zeitleiste der Organisation anzeigen",
	"organizations.superAdminShow": "Super Admin Detailansicht",
	"organizations.licenseShow": "Lizenz-Detailansicht",
	"organizations.supportShow": "Support-Detailansicht",
	"incomingLicense.incomingRequestList": "Eingehende Lizenzanfragen",
	"incomingLicense.incomingAssignCustomer": "Bestehenden Kunden der Maschine zuordnen",
	"incomingLicense.createNewCustomer": "Erstellen Sie einen neuen Kunden und weisen Sie ihn der Maschine zu",
	"widget.userStatistics": "Statistik-Widgets",
	"roles.index": "Alle Rollen anzeigen",
	"roles.store": "Rolle erstellen",
	"roles.show": "Rolle anzeigen",
	"roles.update": "Rolle aktualisieren",
	"roles.destroy": "Rolle löschen",
	"users.index": "Alle Benutzer anzeigen",
	"users.store": "Benutzer erstellen",
	"users.archive": "Liste der Archivbenutzer anzeigen",
	"users.show": "Benutzerdetails anzeigen",
	"users.update": "Benutzer aktualisieren",
	"users.destroy": "Benutzer löschen",
	"users.restore": "Benutzer wiederherstellen",
	"emailTemplate.index": "Alle E-Mail-Vorlagen anzeigen",
	"emailTemplate.store": "E-Mail-Vorlage erstellen",
	"emailTemplate.show": "E-Mail-Vorlage anzeigen",
	"emailTemplate.update": "E-Mail-Vorlage aktualisieren",
	"emailTemplate.destroy": "E-Mail-Vorlage löschen",
	"emailTemplateVariable.store": "E-Mail-Vorlagenvariable erstellen",
	"emailTemplateVariable.destroy": "E-Mail-Vorlagenvariable löschen",
	"permissions.index": "Alle Berechtigungen anzeigen",
	"licenses.incoming": "Eingehende Lizenzen",
	"licenses.machine_list": "Maschinenliste",
	"licenses.license_numbers": "Lizenznummern",
	"customerLicense.machineIndex": "Kundenmaschinen",
	"customerLicense.machineStore": "Kundenrechner erstellen",
	"customerLicense.machineShow": "Kundencomputer anzeigen",
	"customerLicense.machineUpdate": "Kundenrechner aktualisieren",
	"customerLicense.machineLicenses": "Maschinenlizenzen",
	"customerLicense.licenseStore": "Kundenlizenz erstellen",
	"customerLicense.sendEmail": "Lizenz-E-Mail senden",
	"customerLicense.moduleList": "Module verwalten",
	"customerLicense.moduleStore": "Modul speichern",
	"customerLicense.moduleUpdate": "Modul-Update",
	"customerLicense.licenseUpdate": "Kundenlizenz-Update",
	"graphs.autoGeneratedLicenseGraph": "Automatisch generiertes Lizenzdiagramm anzeigen",
	"graphs.upcomingCreatedLicenses": "Zeigt das Diagramm der anstehenden erstellten Lizenzen an",

	"bird_hub.credentials.index": "Alle Birdhub-Anmeldeinformationen anzeigen",
	"bird_hub.credentials.show": "Sehen Sie sich den Birdhub-Berechtigungsnachweis an",
	"bird_hub.credentials.store": "Erstellen Sie einen Birdhub-Berechtigungsnachweis",
	"bird_hub.credentials.update": "Aktualisieren Sie den Birdhub-Berechtigungsnachweis",

	"phoenix.incomingLicenseRequestList": "Phoenix Incoming Lizenzanfragen",
	"phoenix.incomingAssignCustomer": "Phoenix Bestehenden Kunden der Maschine zuordnen",
	"phoenix.createNewCustomer": "Phoenix Erstellen und Zuweisen eines neuen Kunden zur Maschine",
	"phoenix.modules.index": "Alle Phoenix-Module anzeigen",
	"phoenix.modules.store": "Erstellen Sie das Phoenix-Modul",
	"phoenix.modules.update": "Aktualisieren Sie das Phoenix-Modul",
	"phoenix.modules.destroy": "Löschen Sie das Phoenix-Modul",
	"phoenix.companies.index": "Alle Unternehmen in Phoenix anzeigen",
	"phoenix.companies.show": "Sehen Sie sich Phoenix Company an",
	"phoenix.subscriptions.index": "Alle Abonnements von Phoenix Company anzeigen",
	"phoenix.subscriptions.show": "Abonnement der Phoenix Company anzeigen",

	"phoenix.packages.index": "Alle Phoenix-Pakete anzeigen",
	"phoenix.packages.update": "Aktualisieren Sie die Phoenix-Pakete",

	"customerPhoenixLicense.changeMasterPassword": "Ändern Sie das Master-Passwort",
	"customerPhoenixLicense.companyIndex": "Alle Firmenlisten anzeigen",
	"customerPhoenixLicense.companyUpdate": "Firmenstatus aktualisieren",
	"customerPhoenixLicense.modulesIndex": "Alle Unternehmensmodullisten anzeigen",
	"customerPhoenixLicense.modulesUpdate": "Firmenmodul aktualisieren",
	"customerPhoenixLicense.subscriptionsIndex": "Alle Unternehmensabonnements anzeigen",
	"customerPhoenixLicense.subscriptionsShow": "Firmenabonnement anzeigen",
	"customerPhoenixLicense.subscriptionsStore": "Firmenabonnement erstellen",

	"tse.history": "Alle TSE-Sicherungsprotokolllisten anzeigen",
	"tse.download.request_create": "Erstellen Sie eine TSE-Backup-Download-Anfrage",
	"tse.download.request_get": "Aktive Sicherungsdaten abrufen",
	"tse.download": "Laden Sie TSE Backup herunter",

	"api.list": "Alle API-Schlüssellisten anzeigen",
	"api.generate": "API-Schlüssel erstellen",
}
export default de;
